import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';

import { ReactComponent as CalendarMonthIcon } from '../../../images/calendar-ic.svg';

import { KeyboardDatePickerStyled } from './DayPickerField.styled';

export const DayPickerField = ({
  onChange,
  name,
  label,
  value,
  ...restProps
}) => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePickerStyled
        autoOk
        disableToolbar
        InputProps={{
          readOnly: true,
        }}
        size="small"
        keyboardIcon={<CalendarMonthIcon />}
        variant="inline"
        color="secondary"
        format="yyyy-MM-DD"
        value={value}
        id={name}
        name={name}
        label={label}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        onChange={(data) => onChange(name, data)}
        {...restProps}
      />
    </MuiPickersUtilsProvider>
  );
};
