import React from 'react';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import Box from '@mui/material/Box';
import { InputField } from '../../FormFields';
import { Button } from '../../UI/Button';

export const WorkerEventForm = ({ onSubmit }) => {
  const initialValues = {
    worker_email: '',
    worker_name: '',
  };

  const validationSchema = yup.object({
    worker_email: yup
      .string()
      .email('Invalid email format')
      .required('Required'),
    worker_name: yup.string().required('Required'),
  });

  const handlerSubmit = async (values, { resetForm }) => {
    await onSubmit(values);
    resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handlerSubmit}
    >
      {({ touched, handleChange, values, errors, isSubmitting }) => (
        <Form>
          <Box mb={1}>
            <InputField
              fullWidth
              error={touched.worker_name && Boolean(errors.worker_name)}
              helperText={touched.worker_name && errors.worker_name}
              value={values.worker_name ?? ''}
              id="worker_name"
              name="worker_name"
              label="Name*"
              onChange={handleChange}
            />
          </Box>
          <Box mb={5}>
            <InputField
              fullWidth
              error={touched.worker_email && Boolean(errors.worker_email)}
              helperText={touched.worker_email && errors.worker_email}
              value={values.worker_email ?? ''}
              id="worker_email"
              name="worker_email"
              label="Email*"
              onChange={handleChange}
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Button type="submit">
              {isSubmitting ? 'Submiting...' : 'Submit'}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
