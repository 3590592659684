import { FILES_FILEDS_NAMES } from './constants';

export const prepareEventsQuery = ({ eventType, date, ...formData }) => {
  return {
    location: formData.location,
    month: date.month < 10 ? `0${date.month}` : date.month,
    year: date.year,
    table: formData.table,
    events_type: `a:1:{i:0;s:5:"${eventType}";}`,
  };
};

export const prepareEventInfo = (formValues, states) => {
  const { eventType, table, location } = formValues;
  const stateName = states.find((state) => state.term_id === location);
  return {
    stateName: stateName.name,
    eventType,
    siteName: table === 'events' ? 'VANILABRIDES' : 'PEONYLUXE',
  };
};

export const createFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (FILES_FILEDS_NAMES.includes(key)) {
      console.log(key, data[key]);
      if (typeof data[key] === 'string') {
        formData.append(key, null);
        // formData.append(key, new File([''], ''));
      }
      if (typeof data[key] === 'object' && data[key].name) {
        formData.append(key, new Blob([data[key]], { type: data[key].type }));
      }
    } else if (key === 'serviceArea') {
      formData.append('serviceArea[]', data[key]);
    } else {
      formData.append(key, data[key]);
    }
  });
  return formData;
};
