import React from 'react';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { Formik, Form } from 'formik';

import {
  InputField,
  LocationSelectField,
  RadioButtonsGroup,
  UploadFileField,
} from '../../FormFields';
import { Button } from '../../UI/Button';
import { PHONE_REG_EXP } from '../../../helpers/constants';
import RadioButton from '../../FormFields/RadioButton/RadioButton';
import { shooterApi } from '../../../sevices/shooterApi';

export const ShooterRegistrationForm = () => {
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    fullName: '',
    email: '',
    phone: '',
    serviceArea: '',
    website: '',
    uploadfile: '',
    skills: 'a:1:{i:0;s:5:"photo";}',
  };

  const validationSchema = yup.object({
    serviceArea: yup
      .string()
      // .min(1, '"You can\'t leave this blank."')
      .required('Required')
      .nullable(),
    fullName: yup.string().required('Required'),
    email: yup
      .string()
      .email('Invalid email format')
      .required('Mail is required'),
    phone: yup
      .string()
      .matches(PHONE_REG_EXP, 'Phone number is not valid')
      .required('Required'),
    website: yup.string(),
    skills: yup.string().required('Required'),
  });

  const onSubmit = async (values, { resetForm }) => {
    const response = await shooterApi.registration(values);
    if (response.result === 'true') {
      resetForm();
      enqueueSnackbar('Shooter Registered', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    if (response.result === 'false') {
      enqueueSnackbar('Oops Something went wrong! Please try again', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          setFieldValue,
          isSubmitting,
          values,
          handleChange,
          touched,
          errors,
        }) => {
          return (
            <Form>
              <Box display="flex" justifyContent="center" mb={4} mt={2}>
                <UploadFileField
                  image
                  onChange={setFieldValue}
                  value={values.uploadfile ?? ''}
                  id="uploadfile"
                  name="uploadfile"
                  label="uploadfile"
                />
              </Box>
              <Box mb={2}>
                <InputField
                  fullWidth
                  error={touched.fullName && Boolean(errors.fullName)}
                  helperText={touched.fullName && errors.fullName}
                  value={values.fullName ?? ''}
                  id="fullName"
                  name="fullName"
                  label="Full name(s)*"
                  onChange={handleChange}
                />
              </Box>
              <Box mb={2}>
                <InputField
                  fullWidth
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  value={values.email ?? ''}
                  id="email"
                  name="email"
                  label="Email*"
                  onChange={handleChange}
                />
              </Box>
              <Box mb={2}>
                <InputField
                  fullWidth
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                  value={values.phone ?? ''}
                  id="phone"
                  name="phone"
                  label="Phone*"
                  onChange={handleChange}
                />
              </Box>
              <Box mb={2}>
                <LocationSelectField
                  // multiple
                  fullWidth
                  id="serviceArea"
                  label="Service area(s)*"
                  name="serviceArea"
                  value={values.serviceArea}
                  error={touched.serviceArea && Boolean(errors.serviceArea)}
                  helperText={touched.serviceArea && errors.serviceArea}
                />
              </Box>
              <Box mb={2}>
                <InputField
                  fullWidth
                  error={touched.website && Boolean(errors.website)}
                  helperText={touched.website && errors.website}
                  value={values.website ?? ''}
                  id="website"
                  name="website"
                  label="My website (Link)"
                  onChange={handleChange}
                />
              </Box>
              <Box mb={3}>
                <RadioButtonsGroup
                  fullWidth
                  id="skills"
                  name="skills"
                  label="Skills:"
                  value={values.skills}
                >
                  <RadioButton
                    value={'a:1:{i:0;s:5:"photo";}'}
                    label="Photographer"
                  />
                  <RadioButton
                    value={'a:1:{i:0;s:5:"video";}'}
                    label="Videographer"
                  />
                </RadioButtonsGroup>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button type="submit">
                  {isSubmitting ? 'Submiting...' : 'Submit'}
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
