import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchEventsAPI } from './eventsAPI';

const initialState = {
  items: null,
  status: '',
  query: {
    table: 'events',
  },
  info: {},
};

export const fetchEventsAsync = createAsyncThunk(
  'events/fetchEvents',
  async (query) => {
    const { data } = await fetchEventsAPI(query);
    return data;
  }
);

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setEventInfo: (state, action) => {
      return {
        ...state,
        info: action.payload,
      };
    },
    setEventQuery: (state, action) => {
      return {
        ...state,
        query: { ...state.query, ...action.payload },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEventsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEventsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.items = action.payload;
      })
      .addCase(fetchEventsAsync.rejected, (state, action) => {
        state.status = 'error';
        state.items = action.payload;
      });
  },
});

export const { setEventInfo, setEventQuery } = eventsSlice.actions;

export default eventsSlice.reducer;
