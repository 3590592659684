import axios from 'axios';
import { createFormData } from '../helpers/utils';

async function add(params) {
  const formData = createFormData(params);
  try {
    const response = await axios.post('/events/save-event.php', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

async function getById(params) {
  try {
    const response = await axios.get('/events/get-current-event.php', {
      params,
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

async function send(params) {
  try {
    const response = await axios.post('/events/send-events.php', { ...params });
    return response.data;
  } catch (error) {
    return error;
  }
}
const getSendEvents = async (params) => {
  try {
    const response = await axios.get('/events/send-events.php', {
      params,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

async function edit(params) {
  const formData = createFormData(params);
  try {
    const response = await axios.post('/events/edit-event.php', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

async function update(params) {
  try {
    const response = await axios.post(
      '/events/event-edited-inform.php',
      params
    );
    return response.data;
  } catch (error) {
    return error;
  }
}

async function del(params) {
  try {
    const response = await axios.post('/events/remove-event.php', params);
    return response.data;
  } catch (error) {
    return error;
  }
}

async function deleteFile(params) {
  try {
    const response = await axios.post('/events/remove_file.php', params);
    return response.data;
  } catch (error) {
    return error;
  }
}
async function confirmedEvents(params) {
  try {
    const response = await axios.post('/events/confirmed-events.php', params);
    return response.data;
  } catch (error) {
    return error;
  }
}
async function confirmedEventsDetails(params) {
  try {
    const response = await axios.post('/events/event-details.php', params);
    return response.data;
  } catch (error) {
    return error;
  }
}

export const eventsApi = {
  add,
  getById,
  send,
  edit,
  update,
  del,
  deleteFile,
  getSendEvents,
  confirmedEvents,
  confirmedEventsDetails,
};
