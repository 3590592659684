import React from 'react';
import PropTypes from 'prop-types';

import Container from '@mui/material/Container';
import { StyledBasePage } from './BasePage.styled';

export const BasePage = ({
  children,
  centered = false,
  withBackground = false,
}) => (
  <StyledBasePage centered={centered} withBackground={withBackground}>
    <Container maxWidth="xl">{children}</Container>
  </StyledBasePage>
);

BasePage.propTypes = {
  children: PropTypes.node.isRequired,
};
export default BasePage;
