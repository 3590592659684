import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchUserAPI } from './authAPI';

const initialState = {
  isLoggined: false,
  status: null,
};

export const fetchUserAsync = createAsyncThunk(
  'auth/fetchUser',
  async (query) => {
    const { result } = await fetchUserAPI(query);
    return result;
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.isLoggined = action.payload;
      });
  },
});

export default authSlice.reducer;
