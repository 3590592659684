import React, { useState } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import { Tooltip } from '../../UI/Tooltip';
import Typography from '@mui/material/Typography';
import {
  GridWrapper,
  CellWrapper,
  RowInCell,
  ShowDayWrapper,
  DayWrapper,
  CurrentDay,
  EventListWrapper,
  EventItemWrapper,
} from './CalendarGrid.styled';
import { EditEventModal } from '../../Modals/EditEventModal';
import { useSelector } from 'react-redux';
import { getEventQuery } from '../../../redux/events/eventsSelectors';
import { eventsApi } from '../../../sevices/eventsApi';

const CalendarGrid = ({ startDay, today, totalDays, events }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenEditEventModal, setIsEditEventModal] = useState(false);
  const [editEventInitialState, setEditEventInitialState] = useState({});
  const [eventId, setEventId] = useState(null);
  const { location, table } = useSelector(getEventQuery);
  const day = startDay.clone().subtract(1, 'day');
  const daysMap = [...Array(totalDays)].map(() => {
    return day.add(1, 'day').clone();
  });
  const isCurrentDay = (day) => moment().isSame(day, 'day');
  const isSelectedMonth = (day) => today.isSame(day, 'month');

  const eventClickHandler = async (eventId) => {
    setEventId(eventId);
    setIsEditEventModal(true);

    const query = {
      id: eventId,
      table,
      location_id: location,
    };
    const event = await eventsApi.getById(query);

    if (event.result === 'true') {
      setIsEditEventModal(true);
      setEditEventInitialState({
        ...event.data,
        start_time: moment(event.data.start_time, 'hh:mm A'),
        uploadfileShot: event.filePathShot,
        uploadfileWedding: event.filePathWedding,
        eventId,
      });
    }

    if (event.result === 'false') {
      enqueueSnackbar('Oops Something went wrong! Please try again', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  return (
    <div style={{ overflow: 'auto' }}>
      <GridWrapper isHeader>
        {[...Array(7)].map((_, i) => (
          <CellWrapper isHeader isSelectedMonth key={i}>
            <RowInCell justifyContent={'center'} alignItems={'flex-end'} pr={1}>
              {moment().day(i).format('ddd')}
            </RowInCell>
          </CellWrapper>
        ))}
      </GridWrapper>
      <GridWrapper>
        {daysMap.map((dayItem) => (
          <CellWrapper
            key={dayItem.unix()}
            isSelectedMonth={isSelectedMonth(dayItem)}
          >
            <RowInCell>
              <ShowDayWrapper>
                <DayWrapper>
                  {isCurrentDay(dayItem) ? (
                    <CurrentDay>{dayItem.format('D')}</CurrentDay>
                  ) : (
                    dayItem.format('D')
                  )}
                </DayWrapper>
              </ShowDayWrapper>
              <EventListWrapper>
                {events &&
                  events
                    .filter(
                      (event) =>
                        event.wedding_date >= dayItem.format('YYYY-MM-DD') &&
                        event.wedding_date <=
                          dayItem.clone().endOf('day').format('YYYY-MM-DD')
                    )
                    .map((event) => (
                      <li
                        key={event.id}
                        onClick={() => eventClickHandler(event.id)}
                      >
                        <Tooltip
                          title={
                            event.worker_name ? event.worker_name : 'Vacant'
                          }
                        >
                          <EventItemWrapper
                            withWorker={Boolean(event.worker_name)}
                          >
                            <Box mr={1}>{`${event.start_time}`}</Box>
                            <Typography fontWeight="bold" variant="inherit">
                              {` ${event.grooms_name} & ${event.brides_name} `}
                            </Typography>
                          </EventItemWrapper>
                        </Tooltip>
                      </li>
                    ))}
              </EventListWrapper>
            </RowInCell>
          </CellWrapper>
        ))}
      </GridWrapper>
      <EditEventModal
        currentEventId={eventId}
        onClose={() => setIsEditEventModal(false)}
        open={isOpenEditEventModal}
        editEventInitialState={editEventInitialState}
      />
    </div>
  );
};

export default CalendarGrid;
