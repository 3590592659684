import React from 'react';
import { StyledDivider, StyledBaseDivider } from './Divider.styled';

const Divider = ({ children }, props) => {
  return children ? (
    <StyledDivider {...props}>{children}</StyledDivider>
  ) : (
    <StyledBaseDivider />
  );
};

export default Divider;
