import axios from 'axios';
import { createFormData } from '../helpers/utils';

async function registration(params) {
  const formData = createFormData(params);
  try {
    const response = await axios.post(
      '/shooter_registration/registration.php',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
}

export const shooterApi = {
  registration,
};
