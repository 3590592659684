import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import { FormControlStyled } from './RadioButtonsGroup.styled';

export const RadioButtonsGroup = ({
  children,
  id,
  label,
  name,
  value,
  ...restProps
}) => (
  <FormControlStyled {...restProps} color="secondary">
    <Box display="flex" alignItems={'center'}>
      <Box mr={2}>
        <FormLabel id={id}>{label}</FormLabel>
      </Box>

      <RadioGroup row name={name} defaultValue={value}>
        {children}
      </RadioGroup>
    </Box>
  </FormControlStyled>
);
