import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';

import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';

import {
  DayPickerField,
  InputField,
  LocationSelectField,
  TimePickerField,
  MenuItemField,
  UploadFileField,
} from '../../FormFields';
import { Button } from '../../UI/Button';
import { getLocationNameById } from '../../../redux/states/statesSelectors';

import { eventsApi } from '../../../sevices/eventsApi';
import {
  getEventQuery,
  getEventTable,
} from '../../../redux/events/eventsSelectors';
import { fetchEventsAsync } from '../../../redux/events/eventsSlice';
import { validationSchema } from './formValidationSchema';
import { FORM_INITIAL_VALUES } from './formInitialValues';

export const EditEventForm = ({
  onClose,
  editEventInitialState,
  currentEventId,
}) => {
  const [initialValues, setInitialValues] = useState(FORM_INITIAL_VALUES);
  const [isOnlyReadForm, setIsOnlyReadForm] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const getLocationName = useSelector(getLocationNameById);
  const eventsQuery = useSelector(getEventQuery);
  const event = useSelector(getEventTable);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setInitialValues(editEventInitialState);
  }, [editEventInitialState]);

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    const preparedNewEventQuery = {
      ...values,
      event_location: getLocationName(values.location_id).name,
      start_time: values.start_time.format('hh:mm A'),
      table: event,
    };
    const response = await eventsApi.edit(preparedNewEventQuery);

    if (response.result === 'true') {
      setIsOnlyReadForm(true);
      dispatch(fetchEventsAsync(eventsQuery));
      enqueueSnackbar('Event changed', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      onClose();
      if (values.worker_email) {
        await eventsApi.update({
          id: currentEventId,
          location_id: values.location_id,
          table: event,
        });
      }
    }
    if (response.result === 'false') {
      enqueueSnackbar('Oops Something went wrong! Please try again', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    setIsSubmitting(false);
  };

  const onFileDelete = async (fileName) => {
    const response = await eventsApi.deleteFile({
      id: currentEventId,
      location_id: eventsQuery.location,
      file_descr: fileName,
    });

    if (response.result === 'true') {
      setInitialValues((prevState) => ({
        ...prevState,
        [`upload${fileName}`]: 'No downloaded file',
      }));
    }
  };

  const onDelete = async () => {
    const successDelete = !confirm('Are you sure?');

    if (successDelete) {
      return null;
    }

    setIsDeleting(true);
    const preparedQuery = {
      id: currentEventId,
      location_id: eventsQuery.location,
      table: event,
    };
    const response = await eventsApi.del(preparedQuery);
    if (response.result === 'true') {
      onClose();
      dispatch(fetchEventsAsync(eventsQuery));
      enqueueSnackbar('Event deleted ', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setIsDeleting(false);
    }
    if (response.result === 'false') {
      setIsDeleting(false);
      enqueueSnackbar('Oops Something went wrong! Please try again', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };
  const onSend = async () => {
    setIsSending(true);
    const preparedQuery = {
      id: [currentEventId],
      location_id: eventsQuery.location,
      table: event,
    };
    const response = await eventsApi.send(preparedQuery);
    if (response.result === 'true') {
      enqueueSnackbar('Event send', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setIsSending(false);
    }
    if (response.result === 'false') {
      setIsSending(false);
      enqueueSnackbar(response.message, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ setFieldValue, values, handleChange, touched, errors }) => {
          return (
            <Form>
              <Grid container spacing={4} rowSpacing={2}>
                <Grid item xs={12} sm={6}>
                  <LocationSelectField
                    disabled={isOnlyReadForm}
                    id="location_id"
                    label="Event Location"
                    name="location_id"
                    fullWidth
                    error={touched.location_id && Boolean(errors.location_id)}
                    helperText={touched.location_id && errors.location_id}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <DayPickerField
                    fullWidth
                    disabled={isOnlyReadForm}
                    value={values.event_date}
                    id="event_date"
                    name="event_date"
                    label="Wedding Date"
                    onChange={setFieldValue}
                    error={touched.event_date && Boolean(errors.event_date)}
                    helperText={touched.event_date && errors.event_date}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    disabled={isOnlyReadForm}
                    fullWidth
                    error={
                      touched.getting_ready_lcation &&
                      Boolean(errors.getting_ready_lcation)
                    }
                    helperText={
                      touched.getting_ready_lcation &&
                      errors.getting_ready_lcation
                    }
                    value={values.getting_ready_lcation ?? ''}
                    id="getting_ready_lcation"
                    name="getting_ready_lcation"
                    label="Getting Ready Location"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    disabled={isOnlyReadForm}
                    fullWidth
                    value={values.ceremony_location ?? ''}
                    error={
                      touched.ceremony_location &&
                      Boolean(errors.ceremony_location)
                    }
                    helperText={
                      touched.ceremony_location && errors.ceremony_location
                    }
                    id="ceremony_location"
                    name="ceremony_location"
                    label="Ceremony Location"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    disabled={isOnlyReadForm}
                    fullWidth
                    value={values.brides_name ?? ''}
                    error={touched.brides_name && Boolean(errors.brides_name)}
                    helperText={touched.brides_name && errors.brides_name}
                    id="brides_name"
                    name="brides_name"
                    label="Bride’s Name"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    disabled={isOnlyReadForm}
                    fullWidth
                    value={values.brides_phone ?? ''}
                    error={touched.brides_phone && Boolean(errors.brides_phone)}
                    helperText={touched.brides_phone && errors.brides_phone}
                    id="brides_phone"
                    name="brides_phone"
                    label="Bride’s Phone Number"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    disabled={isOnlyReadForm}
                    fullWidth
                    value={values.grooms_name ?? ''}
                    error={touched.grooms_name && Boolean(errors.grooms_name)}
                    helperText={touched.grooms_name && errors.grooms_name}
                    id="grooms_name"
                    name="grooms_name"
                    label="Groom’s Name"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    disabled={isOnlyReadForm}
                    fullWidth
                    value={values.grooms_phone ?? ''}
                    error={touched.grooms_phone && Boolean(errors.grooms_phone)}
                    helperText={touched.grooms_phone && errors.grooms_phone}
                    id="grooms_phone"
                    name="grooms_phone"
                    label="Groom’s Phone:"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    value={values.worker_email ?? ''}
                    fullWidth
                    disabled
                    id="worker_email"
                    name="worker_email"
                    label="Shooter: (readonly field)"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TimePickerField
                    fullWidth
                    disabled={isOnlyReadForm}
                    value={values.start_time}
                    id="start_time"
                    name="start_time"
                    label="Start time"
                    onChange={setFieldValue}
                    error={touched.start_time && Boolean(errors.start_time)}
                    helperText={touched.start_time && errors.start_time}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    disabled={isOnlyReadForm}
                    fullWidth
                    select
                    value={values.photographer_videographer_start_time ?? ''}
                    error={
                      touched.photographer_videographer_start_time &&
                      Boolean(errors.photographer_videographer_start_time)
                    }
                    helperText={
                      touched.photographer_videographer_start_time &&
                      errors.photographer_videographer_start_time
                    }
                    id="photographer_videographer_start_time"
                    name="photographer_videographer_start_time"
                    label="Photographer/Videographer"
                    onChange={handleChange}
                  >
                    <MenuItemField value={'a:1:{i:0;s:5:"photo";}'}>
                      Photographer
                    </MenuItemField>
                    <MenuItemField value={'a:1:{i:0;s:5:"video";}'}>
                      Videographer
                    </MenuItemField>
                  </InputField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    fullWidth
                    disabled={isOnlyReadForm}
                    value={values.coverage ?? ''}
                    error={touched.coverage && Boolean(errors.coverage)}
                    helperText={touched.coverage && errors.coverage}
                    id="coverage"
                    name="coverage"
                    label="Coverage"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    disabled={isOnlyReadForm}
                    fullWidth
                    error={
                      touched.booked_package && Boolean(errors.booked_package)
                    }
                    helperText={touched.booked_package && errors.booked_package}
                    value={values.booked_package ?? ''}
                    id="booked_package"
                    name="booked_package"
                    label="Booked Package"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    disabled={isOnlyReadForm}
                    fullWidth
                    error={touched.pay && Boolean(errors.pay)}
                    helperText={touched.pay && errors.pay}
                    value={values.pay ?? ''}
                    id="pay"
                    name="pay"
                    label="Rate"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <UploadFileField
                    value={values.uploadfileWedding}
                    id="uploadfileWedding"
                    name="uploadfileWedding"
                    label="Upload File Wedding Timeline"
                    onChange={setFieldValue}
                    onDelete={onFileDelete}
                    disabled={isOnlyReadForm}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <UploadFileField
                    value={values.uploadfileShot}
                    id="uploadfileShot"
                    name="uploadfileShot"
                    label="Upload File Shot List"
                    onChange={setFieldValue}
                    disabled={isOnlyReadForm}
                    onDelete={onFileDelete}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} justifyContent={'center'} mt={2}>
                <Grid item xs={9} sm={4}>
                  <Button
                    type="button"
                    onClick={onSend}
                    disabled={Boolean(values.worker_email)}
                  >
                    {isSending ? 'Sending...' : 'Send event'}
                  </Button>
                </Grid>
                <Grid item xs={9} sm={4}>
                  {isOnlyReadForm ? (
                    <Button
                      sx
                      type="button"
                      onClick={() => setIsOnlyReadForm(false)}
                    >
                      Edit event
                    </Button>
                  ) : (
                    <Button type="button" onClick={() => onSubmit(values)}>
                      {isSubmitting ? 'Saving...' : 'Save event'}
                    </Button>
                  )}
                </Grid>
                <Grid item xs={9} sm={4}>
                  <Button color="error" type="button" onClick={onDelete}>
                    {isDeleting ? 'Deleting...' : 'Delete event'}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
