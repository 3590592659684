import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Box from '@material-ui/core/Box';
import Divider from '../UI/Divider';
import Modal from '../UI/Modal/Modal';
import { EditEventForm } from '../Forms';
import { useMediaQuery } from '@mui/material';

export const EditEventModal = ({
  editEventInitialState,
  currentEventId,
  ...props
}) => {
  const sm = useMediaQuery('(max-width:600px)');
  return (
    <Modal
      {...props}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            maxWidth: '680px',
          },
        },
      }}
      fullWidth
      scroll={'body'}
    >
      <DialogTitle>
        <Box my={sm ? 2 : 3} px={sm ? 0 : 4}>
          <Divider>
            <Typography variant="h3" align="center">
              Edit Event
            </Typography>
          </Divider>
        </Box>
      </DialogTitle>
      <Box px={sm ? 0 : 7}>
        <EditEventForm
          onClose={props.onClose}
          editEventInitialState={editEventInitialState}
          currentEventId={currentEventId}
        />
      </Box>
    </Modal>
  );
};
