import React from 'react';
import Box from '@mui/material/Box';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';

import { InputField } from '../../FormFields';
import { Button } from '../../UI/Button';
import Typography from '@mui/material/Typography';

import { fetchUserAsync } from '../../../redux/auth/authSlice';
import { getAuthStatus, getUserAuth } from '../../../redux/auth/authSelectors';

export const LoginForm = () => {
  const history = useHistory();

  const isLoggedIn = useSelector(getUserAuth);
  const authStatus = useSelector(getAuthStatus);
  const error = !isLoggedIn && authStatus === 'success';
  const dispatch = useDispatch();
  const initialValues = {
    password: '',
  };

  const validationSchema = yup.object({
    password: yup.string().required('Required'),
  });

  const onSubmit = async (values) => {
    await dispatch(fetchUserAsync(values));

    if (isLoggedIn) {
      history.push('/');
    }
  };

  return (
    <>
      {isLoggedIn ? (
        <Redirect to="/" />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, values, handleChange, touched, errors }) => {
            return (
              <Form>
                <Box mb={3}>
                  <Typography variant="h3" align="center">
                    Enter your admin password
                  </Typography>
                </Box>
                <Box mb={5}>
                  <InputField
                    fullWidth
                    error={
                      (touched.password && Boolean(errors.password)) || error
                    }
                    helperText={
                      (touched.password && errors.password) ||
                      (error && 'Incorrect password')
                    }
                    value={values.password ?? ''}
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    onChange={handleChange}
                  />
                </Box>
                <Box display="flex" justifyContent="center">
                  <Button type="submit">
                    {isSubmitting ? 'Submiting...' : 'Submit'}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
