import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';

import {
  InputField,
  LocationSelectField,
  MenuItemField,
  MonthPicker,
} from '../../FormFields';
import { Button } from '../../UI/Button';

import { prepareEventsQuery, prepareEventInfo } from '../../../helpers/utils';

import {
  setEventInfo,
  setEventQuery,
  fetchEventsAsync,
} from '../../../redux/events/eventsSlice';
import { getLocations } from '../../../redux/states/statesSelectors';
import { fetchLocationAsync } from '../../../redux/states/locationsSlice';
import {
  getAllEvents,
  getEventStatus,
} from '../../../redux/events/eventsSelectors';

export const CheckEventsForm = () => {
  const dispatch = useDispatch();
  const locationsState = useSelector(getLocations);
  const events = useSelector(getAllEvents);
  const eventStatus = useSelector(getEventStatus);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(fetchLocationAsync());
  }, [dispatch]);

  const validationSchema = yup.object({
    location: yup.string().required('Required, please choose a state'),
    eventType: yup.string().required('Required, please choose a event'),
    date: yup
      .object({
        year: yup.number(),
        month: yup.number(),
      })
      .required('Required, please choose a period'),
  });

  const initialValues = {
    location: '',
    date: {
      month: null,
      year: null,
    },
    eventType: '',
    table: 'events',
  };

  const onSubmit = async (values) => {
    const eventsQuery = prepareEventsQuery(values);
    const eventInfo = prepareEventInfo(values, locationsState);

    dispatch(setEventQuery(eventsQuery));
    dispatch(setEventInfo(eventInfo));

    return dispatch(fetchEventsAsync(eventsQuery));
  };

  {
    !events &&
      eventStatus === 'success' &&
      enqueueSnackbar('No events found', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
  }
  return (
    <>
      <Box mb={1}>
        <Typography variant="h1" align="center">
          Check Events
        </Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ dirty, isValid, setFieldValue, isSubmitting }) => {
          return (
            <Form>
              <Grid
                container
                spacing={3}
                justifyContent="center"
                alignItems="flex-end"
              >
                <Grid item xs={12} sm={4} lg={3}>
                  <LocationSelectField
                    id="location"
                    fullWidth
                    label="Location"
                    name="location"
                  />
                </Grid>

                <Grid item xs={12} sm={4} lg={3}>
                  <Field
                    fullWidth
                    as={MonthPicker}
                    name="date"
                    label="Date"
                    onChange={(data) => setFieldValue('date', data)}
                  />
                </Grid>

                <Grid item xs={12} sm={4} lg={3}>
                  <Field
                    fullWidth
                    as={InputField}
                    select
                    variant="standard"
                    id="eventType"
                    label="Photo/Video Events"
                    name="eventType"
                    color="secondary"
                  >
                    <MenuItemField value="photo">Photo Events</MenuItemField>
                    <MenuItemField value="video">Video Events</MenuItemField>
                  </Field>
                </Grid>

                <Grid item xs={12} md={2} lg={2}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button type="Submit" disabled={!dirty || !isValid}>
                      {isSubmitting ? 'Loading...' : 'Check events'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
