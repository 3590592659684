import { KeyboardDatePicker } from '@material-ui/pickers';
import styled from '@emotion/styled';

export const KeyboardDatePickerStyled = styled(KeyboardDatePicker)`
  font-size: 12px;
  width: 100%;
  font-family: 'Gilroy' !important;
  & .MuiFormLabel-root {
    font-size: 12px;
    width: 100%;
    font-family: 'Gilroy' !important;
    etter-spacing: 0.5px;
    &.Mui-focused {
      background: none;
      color: ${(props) => props.theme.palette.secondary.main};
    }
  }
  & .MuiInputBase-input {
    height: auto;
    padding-bottom: 4px;
    font-size: 12px;
    font-family: 'Gilroy';
    &.Mui-focused {
      border-bottom-color: ${(props) => props.theme.palette.secondary.main};
      background: ${(props) => props.theme.palette.secondary.main};
      &::after,
      &::before {
        border-bottom-color: ${(props) => props.theme.palette.secondary.main};
        background: ${(props) => props.theme.palette.secondary.main};
      }
    }
  }

  & .MuiInput-colorSecondary.MuiInput-underline:after {
    border-bottom-color: ${(props) => props.theme.palette.secondary.main};
  }

  & .Mui-error {
    color: ${(props) => props.theme.palette.error.main} !important;
    &.MuiInput-underline.Mui-error:after {
      border-bottom-color: ${(props) => props.theme.palette.error.main};
    }
  }
`;
