import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { ReactComponent as CalendarMonthIcon } from '../../../images/calendar-ic.svg';

import {
  MonthPickerWrapper,
  MonthPickerCalendarContainer,
  MonthPickerCalendarControls,
  MonthPickerCalendarYear,
  MonthPickerCalendar,
  MonthPickerCalendarCell,
} from './MonthPicker.styled';
import Divider from '../../UI/Divider';
export const MonthPicker = ({ onChange, value, ...props }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [year, setYear] = useState(() => moment().year());
  const [month, setMonth] = useState(() => moment().month());
  const dropdownRef = useRef(null);

  const monthMap = moment.monthsShort();

  const prevHandler = () => {
    setYear((prev) => prev - 1);
  };
  const nextHandler = () => {
    setYear((prev) => prev + 1);
  };

  const selectMonthHandler = (index) => {
    setMonth(index);
    setTimeout(() => {
      setInputValue(`${monthMap[index]} ${year}`);
      setOpen(false);
    }, 0);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (open) {
      setInputValue(`${monthMap[month]} ${year}`);
      onChange({ year, month: month + 1 });
    }
  }, [year, month, open, value.year]);

  return (
    <MonthPickerWrapper ref={dropdownRef}>
      <TextField
        variant="standard"
        color="secondary"
        value={inputValue}
        onClick={() => setOpen(!open)}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="medium" disableRipple>
                <CalendarMonthIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />{' '}
      {open ? (
        <MonthPickerCalendarContainer>
          <MonthPickerCalendarControls>
            <IconButton disableRipple size="small" onClick={prevHandler}>
              <ArrowLeftIcon />
            </IconButton>
            <MonthPickerCalendarYear>{year}</MonthPickerCalendarYear>
            <IconButton disableRipple size="small" onClick={nextHandler}>
              <ArrowRightIcon />
            </IconButton>
          </MonthPickerCalendarControls>
          <Box mb={2}>
            <Divider />
          </Box>
          <MonthPickerCalendar>
            {monthMap.map((monthName, index) => (
              <MonthPickerCalendarCell
                onClick={() => selectMonthHandler(index)}
                key={index}
                isSelected={index === month}
              >
                {monthName}
              </MonthPickerCalendarCell>
            ))}
          </MonthPickerCalendar>
        </MonthPickerCalendarContainer>
      ) : null}
    </MonthPickerWrapper>
  );
};
