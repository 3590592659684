import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';

import Divider from '../UI/Divider';
import { Button } from '../UI/Button';

const EventCard = ({ event, onEventSelect }) => {
  const [checked, setChecked] = useState(false);
  const handlerClickAcceptButton = () => {
    setChecked(!checked);
    onEventSelect(event.id);
  };
  return (
    <Box
      p={4}
      border="1px solid"
      display="flex"
      flexDirection="column"
      sx={{
        height: '100%',
      }}
    >
      <Typography variant="h3" align="center">
        {`${event.brides_name} & ${event.grooms_name}`}
      </Typography>
      <Box mt={3}>
        <Divider />
      </Box>
      <Box mt={3} display="flex">
        <Box flexGrow={1}>Date:</Box>
        <Box>{event.event_date}</Box>
      </Box>
      <Box mt={2.5} display="flex">
        <Box flexGrow={1}>Location:</Box>
        <Box>{event.event_location}</Box>
      </Box>
      <Box mt={2.5} display="flex">
        <Box flexGrow={1}>Start Time:</Box>
        <Box>{event.start_time}</Box>
      </Box>
      <Box mt={2.5} display="flex">
        <Box flexGrow={1}>Hours of Coverage:</Box>
        <Box>{event.coverage}</Box>
      </Box>
      <Box mt={2.5} mb={4} display="flex">
        <Box flexGrow={1}>Rate: </Box>
        <Box>{event.pay}$</Box>
      </Box>
      <Box sx={{ marginTop: 'auto' }} display="flex" justifyContent="center">
        <Button
          variant={checked ? 'outlined' : 'contained'}
          color="success"
          onClick={handlerClickAcceptButton}
          endIcon={
            checked ? <CheckIcon color="success" fontSize="small" /> : ''
          }
        >
          {checked ? 'Accepted' : 'Accept'}
        </Button>
      </Box>
    </Box>
  );
};

export default EventCard;
