import MenuItem from '@mui/material/MenuItem';
import styled from '@emotion/styled';

export const StyledMenuItem = styled(MenuItem)`
  padding: 14px 16px;
  &:hover,
  &.Mui-selected,
  &.MuiMenuItem-selected,
  &.Mui-active {
    background: rgba(188, 149, 126, 0.5);
  }
`;
