import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';

import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';

import {
  DayPickerField,
  InputField,
  LocationSelectField,
  TimePickerField,
  MenuItemField,
  UploadFileField,
} from '../../FormFields';
import { Button } from '../../UI/Button';
import { getLocationNameById } from '../../../redux/states/statesSelectors';
import { PHONE_REG_EXP } from '../../../helpers/constants';
import { eventsApi } from '../../../sevices/eventsApi';
import {
  getEventQuery,
  getEventTable,
} from '../../../redux/events/eventsSelectors';
import { fetchEventsAsync } from '../../../redux/events/eventsSlice';

export const AddEventForm = () => {
  const dispatch = useDispatch();
  const getLocationName = useSelector(getLocationNameById);
  const eventsQuery = useSelector(getEventQuery);
  const event = useSelector(getEventTable);
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = yup.object({
    location_id: yup.string().required('Required, choose a state'),
    event_date: yup.date().nullable().required('Required, set wedding Date'),
    getting_ready_lcation: yup.string().required('Required'),
    ceremony_location: yup
      .string()
      .typeError('you must specify a string')
      .required('Required'),
    brides_name: yup
      .string()
      .typeError('you must specify a string')
      .required('Required'),
    brides_phone: yup
      .string()
      .matches(PHONE_REG_EXP, 'Phone number is not valid')
      .required('Required'),
    grooms_name: yup
      .string()
      .typeError('you must specify a string')
      .required('Required'),
    grooms_phone: yup
      .string()
      .matches(PHONE_REG_EXP, 'Phone number is not valid')
      .required('Required'),
    coverage: yup
      .number()
      .typeError('you must specify a number')
      .required('Required'),
    start_time: yup.date().nullable().required('Required, set time'),
    photographer_videographer_start_time: yup.string().required('Required'),
    booked_package: yup.string().required('Required'),
  });

  const initialValues = {
    location_id: '',
    event_date: null,
    getting_ready_lcation: '',
    ceremony_location: '',
    brides_name: '',
    brides_phone: '',
    grooms_name: '',
    grooms_phone: '',
    worker_email: '',
    start_time: null,
    photographer_videographer_start_time: '',
    booked_package: '',
    coverage: '',
    pay: '',
    event_location: '',
    uploadfileWedding: '',
    uploadfileShot: '',
    table: event,
  };

  const onSubmit = async (values, { resetForm }) => {
    const preparedNewEventQuery = {
      ...values,
      event_location: getLocationName(values.location_id).name,
      event_date: values.event_date.format('yyyy-MM-DD'),
      start_time: values.start_time.format('hh:mm A'),
    };
    const response = await eventsApi.add(preparedNewEventQuery);
    if (response.result === 'true') {
      resetForm();
      dispatch(fetchEventsAsync(eventsQuery));
      enqueueSnackbar('Event added successfully ', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    if (response.result === 'false') {
      enqueueSnackbar('Oops Something went wrong! Please try again', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({
          setFieldValue,
          isSubmitting,
          values,
          handleChange,
          touched,
          errors,
        }) => {
          return (
            <Form>
              <Grid container spacing={4} rowSpacing={2}>
                <Grid item xs={12} sm={6}>
                  <LocationSelectField
                    id="location_id"
                    label="Event Location"
                    name="location_id"
                    fullWidth
                    error={touched.location_id && Boolean(errors.location_id)}
                    helperText={touched.location_id && errors.location_id}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <DayPickerField
                    value={values.event_date}
                    fullWidth
                    id="event_date"
                    name="event_date"
                    label="Wedding Date"
                    onChange={setFieldValue}
                    error={touched.event_date && Boolean(errors.event_date)}
                    helperText={touched.event_date && errors.event_date}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    fullWidth
                    error={
                      touched.getting_ready_lcation &&
                      Boolean(errors.getting_ready_lcation)
                    }
                    helperText={
                      touched.getting_ready_lcation &&
                      errors.getting_ready_lcation
                    }
                    value={values.getting_ready_lcation}
                    id="getting_ready_lcation"
                    name="getting_ready_lcation"
                    label="Getting Ready Location"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    fullWidth
                    value={values.ceremony_location}
                    error={
                      touched.ceremony_location &&
                      Boolean(errors.ceremony_location)
                    }
                    helperText={
                      touched.ceremony_location && errors.ceremony_location
                    }
                    id="ceremony_location"
                    name="ceremony_location"
                    label="Ceremony Location"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    fullWidth
                    value={values.brides_name}
                    error={touched.brides_name && Boolean(errors.brides_name)}
                    helperText={touched.brides_name && errors.brides_name}
                    id="brides_name"
                    name="brides_name"
                    label="Bride’s Name"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    fullWidth
                    value={values.brides_phone}
                    error={touched.brides_phone && Boolean(errors.brides_phone)}
                    helperText={touched.brides_phone && errors.brides_phone}
                    id="brides_phone"
                    name="brides_phone"
                    label="Bride’s Phone Number"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    fullWidth
                    value={values.grooms_name}
                    error={touched.grooms_name && Boolean(errors.grooms_name)}
                    helperText={touched.grooms_name && errors.grooms_name}
                    id="grooms_name"
                    name="grooms_name"
                    label="Groom’s Name"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    fullWidth
                    value={values.grooms_phone}
                    error={touched.grooms_phone && Boolean(errors.grooms_phone)}
                    helperText={touched.grooms_phone && errors.grooms_phone}
                    id="grooms_phone"
                    name="grooms_phone"
                    label="Groom’s Phone:"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    fullWidth
                    disabled
                    id="worker_email"
                    name="worker_email"
                    label="Shooter: (readonly field)"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TimePickerField
                    value={values.start_time}
                    fullWidth
                    id="start_time"
                    name="start_time"
                    label="Start time"
                    onChange={setFieldValue}
                    error={touched.start_time && Boolean(errors.start_time)}
                    helperText={touched.start_time && errors.start_time}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    fullWidth
                    select
                    value={values.photographer_videographer_start_time}
                    error={
                      touched.photographer_videographer_start_time &&
                      Boolean(errors.photographer_videographer_start_time)
                    }
                    helperText={
                      touched.photographer_videographer_start_time &&
                      errors.photographer_videographer_start_time
                    }
                    id="photographer_videographer_start_time"
                    name="photographer_videographer_start_time"
                    label="Photographer/Videographer"
                    onChange={handleChange}
                  >
                    <MenuItemField value={'a:1:{i:0;s:5:"photo";}'}>
                      Photographer
                    </MenuItemField>
                    <MenuItemField value={'a:1:{i:0;s:5:"video";}'}>
                      Videographer
                    </MenuItemField>
                  </InputField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    fullWidth
                    value={values.coverage}
                    error={touched.coverage && Boolean(errors.coverage)}
                    helperText={touched.coverage && errors.coverage}
                    id="coverage"
                    name="coverage"
                    label="Coverage"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    fullWidth
                    error={
                      touched.booked_package && Boolean(errors.booked_package)
                    }
                    helperText={touched.booked_package && errors.booked_package}
                    value={values.booked_package}
                    id="booked_package"
                    name="booked_package"
                    label="Booked Package"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    fullWidth
                    error={touched.pay && Boolean(errors.pay)}
                    helperText={touched.pay && errors.pay}
                    value={values.pay}
                    id="pay"
                    name="pay"
                    label="Rate"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <UploadFileField
                    value={values.uploadfileWedding}
                    id="uploadfileWedding"
                    name="uploadfileWedding"
                    label="Upload File Wedding Timeline"
                    onChange={setFieldValue}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <UploadFileField
                    value={values.uploadfileShot}
                    id="uploadfileShot"
                    name="uploadfileShot"
                    label="Upload File Shot List"
                    onChange={setFieldValue}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button type="submit">
                      {isSubmitting ? 'Saving...' : 'Save event'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
