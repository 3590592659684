import { Button } from '@mui/material';
import styled from '@emotion/styled';

export const ButtonStyled = styled(Button)`
  &.MuiButton-root {
    border-radius: 0 !important;
    font-family: ${({ theme }) => theme.typography.body1.fontFamily};
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    font-weight: 500;
    letter-spacing: 1px;
    line-height: ${(p) => p.size === 'iconButton' && 0};
    min-width: ${(p) => p.size === 'iconButton' && '30px'};
    width: ${(p) => p.size === 'iconButton' && '30px'};
    padding: ${(p) => p.size === 'iconButton' && '4px'};
    height: ${(p) => p.size === 'iconButton' && '30px'};
    color: ${(p) => p.variant === 'contained' && '#fff'};
    border: ${(p) => p.variant === 'contained' && '1px solid transparent'};
  }

  &.MuiButton-sizeLarge {
    padding: 18px;
    min-width: 240px;
  }
  &.MuiButton-sizeMedium {
    padding: 14px;
    width: 100%;
    max-width: 200px;
  }
`;
