import React from 'react';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

const paperStyles = {
  style: {
    overflow: 'visible',
    boxShadow: 'none',
    border: 'none',
  },
};

const iconButtonStyles = {
  position: 'absolute',
  right: -5,
  top: -30,
  color: '#fff',
};

const Modal = (props) => (
  <Dialog {...props} PaperProps={paperStyles}>
    {props.onClose ? (
      <IconButton
        size="small"
        disableRipple
        aria-label="close"
        onClick={props.onClose}
        sx={iconButtonStyles}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    ) : null}
    <Box p={props.p ? props.p : 3}>{props.children}</Box>
  </Dialog>
);

export default Modal;
