import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Box from '@material-ui/core/Box';
import Divider from '../UI/Divider';
import Modal from '../UI/Modal/Modal';
import { Button } from '../UI/Button';

export const SentEventConfirmationModal = (props) => (
  <Modal {...props}>
    <DialogTitle>
      <Typography variant="h1" align="center">
        all events sent
      </Typography>
    </DialogTitle>
    <Box p={1} mb={3}>
      <Divider />
    </Box>
    <Box display="flex" justifyContent="center" mb={3}>
      <Button onClick={props.onClose}>Continue</Button>
    </Box>
  </Modal>
);
