import FormControl from '@mui/material/FormControl';

import styled from '@emotion/styled';

export const StyledSelectField = styled(FormControl)`
  & .MuiInput-root {
    &.Mui-focused {
      background: none;
    }

    .MuiSelect-select,
    .MuiInput-input,
    .MuiInputBase-input {
      &:focus {
        background: none !important;
      }
    }

    .MuiList-root,
    .MuiMenu-list {
      padding-top: 0;
    }
  }
  .MuiList-root,
  .MuiMenu-list {
    padding-top: 0;
  }
  & .MuiSelect-icon,
  & .MuiSvgIcon-root {
    color: ${(props) => props.theme.palette.secondary.main}!important;
  }
`;
