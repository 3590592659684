import React from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

const RadioButton = ({ value, label }) => (
  <FormControlLabel
    value={value}
    control={<Radio color="secondary" />}
    label={label}
  />
);

export default RadioButton;
