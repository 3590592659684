import styled from '@emotion/styled';
export const InputStyled = styled('input')`
  width: 0;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  left: -9999px;
`;

export const LabelStyled = styled('label')`
  cursor: ${(p) => (p.disabled ? '' : 'pointer')};
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
`;

export const LabelImageStyled = styled('label')`
  overflow: hidden;
  position: relative;
  cursor: ${(p) => (p.disabled ? '' : 'pointer')};
  width: 282px;
  height: 282px;
  border: 1px solid ${(p) => p.theme.palette.secondary.main};
  display: flex;
  border-radius: 50%;

  img {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
