import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { BasePage } from '../../components/BasePage';
import Divider from '../../components/UI/Divider';

import { eventsApi } from '../../sevices/eventsApi';
import EventCard from '../../components/EventCard/EventCard';
import { Button } from '../../components/UI/Button';
import { Modal } from '../../components/UI/Modal';
import { WorkerEventForm } from '../../components/Forms';

import { ReactComponent as SuccessIcon } from './images/done_ring_round_light.svg';

const WorkerEventsView = () => {
  const sm = useMediaQuery('(max-width:600px)');
  const [events, setEvents] = useState(null);
  const [selectedEventsList, setSelectedEventsList] = useState([]);
  const [isWorkerModalOpen, setIsWorkerModalOpen] = useState(false);
  const [isSuccessWorkerModalOpen, setIsSuccessWorkerModalOpen] =
    useState(false);
  const query = queryString.parse(useLocation().search);

  useEffect(() => {
    eventsApi.getSendEvents(query).then((resp) => {
      resp.result === 'true' ? setEvents(resp.data) : setEvents([]);
    });
  }, [query.id, query.table]);

  if (events === null) {
    return null;
  }

  if (events.length === 0) {
    return (
      <Box mt={sm ? 5 : 10}>
        <Typography variant="h2" align="center">
          Events not found
        </Typography>
      </Box>
    );
  }

  const eventLocationTitle = Boolean(events) && events[0].event_location;

  const handlerClickEvent = (eventId) => {
    const newSelectedEventsList = selectedEventsList.includes(eventId)
      ? selectedEventsList.filter((id) => id !== eventId)
      : [...selectedEventsList, eventId];
    setSelectedEventsList(newSelectedEventsList);
  };

  const handlerSubmit = async (formValues) => {
    const confirmedEventsQery = {
      ...formValues,
      tableName: query.table,
      idArray: selectedEventsList,
    };
    const response = await eventsApi.confirmedEvents(confirmedEventsQery);

    if (response.result) {
      setIsWorkerModalOpen(false);
      setIsSuccessWorkerModalOpen(true);
      await eventsApi.confirmedEventsDetails(confirmedEventsQery);
      await eventsApi.getSendEvents(query).then((resp) => {
        resp.result === 'true' ? setEvents(resp.data) : setEvents([]);
      });
      setTimeout(() => {
        setIsSuccessWorkerModalOpen(false);
      }, 3000);
    }
  };

  return (
    <BasePage>
      <Box mt={sm ? 5 : 10}>
        <Typography variant="h1" align="center">
          {eventLocationTitle} Events
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body1" color="muted" align="center">
          Mark the events that suit you and click submit
        </Typography>
      </Box>
      <Box mb={5}>
        <Divider />
      </Box>

      <Grid container spacing={3}>
        {events.map((event) => (
          <Grid key={event.id} item xs={12} sm={6} md={4}>
            <EventCard event={event} onEventSelect={handlerClickEvent} />
          </Grid>
        ))}
      </Grid>
      <Box mt={3} display="flex" justifyContent="center">
        <Button type="button" onClick={() => setIsWorkerModalOpen(true)}>
          Submit
        </Button>
      </Box>
      <Modal
        open={isWorkerModalOpen}
        onClose={() => setIsWorkerModalOpen(false)}
        p={4}
      >
        <Box mb={2}>
          <Typography variant="h1" align="center">
            complete form
          </Typography>
        </Box>
        <WorkerEventForm onSubmit={handlerSubmit} />
      </Modal>
      <Modal
        open={isSuccessWorkerModalOpen}
        onClose={() => setIsSuccessWorkerModalOpen(false)}
        p={4}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              maxWidth: '430px',
            },
          },
        }}
      >
        <Box display="flex" justifyContent="center">
          <SuccessIcon />
        </Box>
        <Box mb={1}>
          <Typography variant="h1" align="center">
            success
          </Typography>
        </Box>
        <Box mb={1}>
          <Divider />
        </Box>
        <Typography align="center">
          Thank you so much for submitting your availability! Our team will be
          confirming you further details.
        </Typography>
      </Modal>
    </BasePage>
  );
};

export default WorkerEventsView;
