import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Box from '@mui/material/Box';
import { CalendarHeader, CalendarGrid, CalendarHistory } from './components';
import { useSelector, useDispatch } from 'react-redux';

import {
  getAllEvents,
  getEventInfo,
  getEventMonth,
  getEventQuery,
  getEventStatus,
  getEventYear,
} from '../../redux/events/eventsSelectors';
import Divider from '../UI/Divider';
import {
  fetchEventsAsync,
  setEventQuery,
} from '../../redux/events/eventsSlice';

const TOTAL_DAYS = 42;

const EventsCalendar = () => {
  const dispatch = useDispatch();
  const eventInfo = useSelector(getEventInfo);
  const eventYear = useSelector(getEventYear);
  const eventMonth = useSelector(getEventMonth);
  const eventQuery = useSelector(getEventQuery);
  const eventStatus = useSelector(getEventStatus);
  const events = useSelector(getAllEvents);

  moment.updateLocale('en');
  const [today, setToday] = useState(moment());
  const startDay = today.clone().startOf('month').startOf('week');

  const fetchEvents = () => {
    const month = today.month() + 1;
    const query = {
      ...eventQuery,
      year: today.year(),
      month: month < 10 ? `0${month}` : month,
    };
    dispatch(setEventQuery(query));
    dispatch(fetchEventsAsync(query));
  };

  useEffect(() => {
    if (!eventYear && !eventMonth) {
      setToday(moment());
    } else {
      setToday(moment().set({ year: eventYear, month: eventMonth - 1 }));
    }
  }, [eventYear, eventMonth]);

  useEffect(() => {
    if (eventStatus === 'success') {
      fetchEvents();
    }
  }, [today]);

  const prevHandler = () => {
    setToday((prev) => prev.clone().subtract(1, 'month'));
  };
  const nextHandler = () => {
    setToday((prev) => prev.clone().add(1, 'month'));
  };

  return (
    <Box>
      <CalendarHeader
        event={eventInfo}
        today={today}
        prevHandler={prevHandler}
        nextHandler={nextHandler}
      />
      <Box mt={2} mb={4}>
        <Divider />
      </Box>
      <CalendarGrid
        startDay={startDay}
        today={today}
        totalDays={TOTAL_DAYS}
        events={events}
      />

      <Box display={'flex'} mt={2}>
        <Box mr={3}>
          <CalendarHistory type="accepted">Accepted</CalendarHistory>
        </Box>
        <Box>
          <CalendarHistory type="vacant">Vacant</CalendarHistory>
        </Box>
      </Box>
    </Box>
  );
};

export default EventsCalendar;
