import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme();

const baseHeadingStyle = {
  textTransform: 'uppercase',
  fontWeight: 400,
};

const theme = createTheme({
  ...defaultTheme,
  fontFamily: ['Gilroy', 'Roboto', 'Arial', 'sans-serif'].join(','),
  palette: {
    primary: {
      main: '#525252',
    },
    secondary: {
      main: '#BC957E',
    },
    error: {
      main: '#DB533D',
    },
    success: {
      main: '#6BC090',
    },
    info: {
      main: '#EDBA21',
    },
    muted: {
      main: '#A8A8A8',
    },
    actions: {
      active: 'rgba(188, 149, 126, 0.5)',
      hover: 'rgba(188, 149, 126, 0.5)',
      selected: 'rgba(188, 149, 126, 0.5)',
      focus: 'rgba(188, 149, 126, 0.5)',
    },
  },
  typography: {
    body1: {
      fontSize: '12px',
      lineHeight: '18px',
      fontFamily: 'Gilroy',
      letterSpacing: '.5px',
    },
    h1: {
      ...baseHeadingStyle,
      fontSize: '36px',
      lineHeight: '54px',
      letterSpacing: '1px',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '24px',
        lineHeight: '36px',
      },
    },
    h2: {
      ...baseHeadingStyle,
      fontSize: '24px',
      lineHeight: '36px',
      letterSpacing: '3px',
      // [defaultTheme.breakpoints.down('md')]: {
      //   fontSize: '30px',
      //   lineHeight: '48px',
      // },
    },
    h3: {
      ...baseHeadingStyle,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '3px',
      // [defaultTheme.breakpoints.down('md')]: {
      //   fontSize: '30px',
      //   lineHeight: '48px',
      // },
    },
  },

  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#fff',
          color: '#525252',
          border: 'none',
          borderRadius: 0,
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          fontSize: '11px',
          lineHeight: '15px',
          fontFamily: 'Gilroy',
          letterSpacing: '.5px',
          bottom: 0,
        },
      },
    },
  },
});

export const snackbarStyles = {
  variantSuccess: { backgroundColor: theme.palette.success.main },
  variantError: { backgroundColor: theme.palette.error.main },
};

export default theme;
