export const FORM_INITIAL_VALUES = {
  location_id: '',
  event_date: null,
  getting_ready_lcation: '',
  ceremony_location: '',
  brides_name: '',
  brides_phone: '',
  grooms_name: '',
  grooms_phone: '',
  worker_email: '',
  start_time: null,
  photographer_videographer_start_time: '',
  booked_package: '',
  coverage: '',
  pay: '',
  event_location: '',
  uploadfileWedding: '',
  uploadfileShot: '',
};
