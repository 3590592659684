import React from 'react';
import Tooltip from '@mui/material/Tooltip';

const TooltipStyled = ({ children, ...restProps }) => {
  return (
    <Tooltip placement="top" {...restProps}>
      {children}
    </Tooltip>
  );
};

export default TooltipStyled;
