import styled from '@emotion/styled';
import imgSrc from './image/bg-main_2.png';

export const StyledBasePage = styled('div')`
  overflow: hidden;
  display: ${(p) => (p.centered ? 'flex' : 'block')};
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-image: ${(p) => (p.withBackground ? `url(${imgSrc})` : 'none')};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  & form {
    width: 100%;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${(p) => p.withBackground && '#fbfbfb'} inset !important;
  }
`;
