import React, { useState, useEffect } from 'react';

import { InputFile } from './InputFile';
import { InputImage } from './InputImage';

export const UploadFileField = ({
  label,
  onChange,
  name,
  value,
  disabled,
  image,
  onDelete,
}) => {
  const [file, setFile] = useState(value);
  useEffect(() => {
    setFile(value);
  }, [value]);

  const handleInputChange = (e) => {
    setFile(e.currentTarget.files[0]);
    onChange(name, e.currentTarget.files[0]);
  };

  const handlerClearFile = () => {
    setFile('');
    onChange(name, '');
  };

  return image ? (
    <InputImage
      label={label}
      name={name}
      handleInputChange={handleInputChange}
      file={file}
      handlerClearFile={handlerClearFile}
    />
  ) : (
    <InputFile
      disabled={disabled}
      label={label}
      name={name}
      onChange={handleInputChange}
      file={file}
      value={value}
      handlerClearFile={handlerClearFile}
      handlerDeleteFile={onDelete}
    />
  );
};
