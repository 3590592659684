import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchLocation } from './locationsApi';

const initialState = {
  states: [],
};

export const fetchLocationAsync = createAsyncThunk(
  'states/fetchLocation',
  async () => {
    const data = await fetchLocation();
    return data;
  }
);

export const locationsSlice = createSlice({
  name: 'states',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchLocationAsync.fulfilled, (state, action) => {
      state.states = action.payload;
    });
  },
});

export default locationsSlice.reducer;
