import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { useSnackbar } from 'notistack';
import { Button } from '../../UI/Button';
import { ReactComponent as PrevArrowIcon } from '../images/prevArrow.svg';
import { ReactComponent as NextArrowIcon } from '../images/nextArrow.svg';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import {
  getEventQuery,
  getEventsIdsWithoutWorkers,
} from '../../../redux/events/eventsSelectors';
import { eventsApi } from '../../../sevices/eventsApi';
import { SentEventConfirmationModal } from '../../Modals/SentEventConfirmationModal';
import { AddEventModal } from '../../Modals/AddEventModal';

const CalendarHeader = ({ event, today, prevHandler, nextHandler }) => {
  const sm = useMediaQuery('(max-width:600px)');
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenAddEventModal, setIsOpenAddEventModal] = useState(false);
  const eventsIdsWithoutWorkersList = useSelector(getEventsIdsWithoutWorkers);
  const { enqueueSnackbar } = useSnackbar();
  const eventQuery = useSelector(getEventQuery);
  const sendAllEventsHandler = async () => {
    const res = await eventsApi.send({
      id: eventsIdsWithoutWorkersList,
      location_id: eventQuery.location,
      table: eventQuery.table,
    });
    if (res.result === 'false') {
      enqueueSnackbar(res.message, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }

    if (res.result === 'true') {
      setIsOpenConfirmModal(true);
    }
  };

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <Box
        display="flex"
        alignItems="center"
        sx={{ width: sm ? '100%' : 'auto' }}
      >
        <Button size="iconButton" onClick={prevHandler}>
          <PrevArrowIcon />
        </Button>
        <Box sx={{ width: sm ? '100%' : '108px', padding: '0 4px' }}>
          <Typography variant="body1" align="center">
            {today.format('MMMM YYYY')}
          </Typography>
        </Box>
        <Button size="iconButton" onClick={nextHandler}>
          <NextArrowIcon />
        </Button>
      </Box>
      <Box
        pr={3}
        pl={3}
        my={sm ? 3 : 0}
        flexGrow={1}
        sx={{ width: sm ? '100%' : 'auto' }}
      >
        <Typography variant="h3" align="center">
          {event.stateName
            ? `${event.stateName} ${event.siteName} ${event.eventType} event`
            : 'Calendar'}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={sm && 'space-between'}
        sx={{ width: sm ? '100%' : 'auto' }}
      >
        <Box mr={1}>
          <Button size="small" onClick={sendAllEventsHandler}>
            Sent Events
          </Button>
        </Box>
        <Button size="small" onClick={() => setIsOpenAddEventModal(true)}>
          Add Event
        </Button>
      </Box>
      <SentEventConfirmationModal
        onClose={() => setIsOpenConfirmModal(false)}
        open={isOpenConfirmModal}
      />
      <AddEventModal
        open={isOpenAddEventModal}
        onClose={() => setIsOpenAddEventModal(false)}
      />
    </Box>
  );
};

export default CalendarHeader;
