import styled from '@emotion/styled';

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 5px;
`;

export const CellWrapper = styled.div`
  height: ${(props) => (props.isHeader ? 40 : 130)}px;
  min-width: 140px;
  font-size: ${(props) => (props.isHeader ? '12px' : '10px')};
  color: #525252;
  opacity: ${(props) => (props.isSelectedMonth ? 1 : 0.5)};
  background-color: ${(props) => (props.isHeader ? 'transparent' : '#f9f9f9')};
`;

export const RowInCell = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'normal')};
  ${(props) => props.pr && `padding-right: ${props.pr * 8}px`}
`;

export const DayWrapper = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  cursor: pointer; ;
`;

export const CurrentDay = styled('div')`
  height: 100%;
  width: 100%;
  background: #bc957e;
  border-radius: 50%;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
`;

export const ShowDayWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export const EventListWrapper = styled('ul')`
  flex-grow: 1;
  overflow: auto;
  margin: unset;
  list-style-type: none;
  list-style-position: inside;
  padding-left: 16px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & li:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const EventItemWrapper = styled('button')`
  position: relative;
  color: #fff;
  font-size: 11px;
  background: ${(p) => (p.withWorker ? '#6BC090' : '#edba21')};
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  border: unset;
  cursor: pointer;
  margin: 0;
  line-height: 1;
  padding: 6px 4px 6px 9px;
  text-align: left;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 100%;
    background: ${(p) =>
      p.withWorker
        ? 'linear-gradient(90deg, rgba(107, 192, 144, 0) 0%, #6BC090 100%);'
        : 'linear-gradient(90deg, rgba(237, 186, 33, 0) 0%, #edba21 100%)'};
  }
`;
