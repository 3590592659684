import React from 'react';
import { InputStyled, LabelImageStyled } from './uploadFile.styled';
import Box from '@mui/material/Box';
import { ReactComponent as UploadIcon } from './images/ic-upload-camera.svg';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import uploadBg from './images/bg-image-shooter.png';

export const InputImage = ({
  label,
  handleInputChange,
  file,
  handlerClearFile,
  name,
}) => {
  const bgImage = file ? URL.createObjectURL(file) : uploadBg;
  return (
    <Box position="relative">
      <LabelImageStyled>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          {file ? null : (
            <>
              <Box>
                <UploadIcon />
              </Box>
              <Typography variant="body1">Add photo</Typography>
            </>
          )}
          <InputStyled
            id={label}
            name={name}
            accept=".jpg, .jpeg, .png"
            type="file"
            onChange={handleInputChange}
          />
        </Box>
        <img src={bgImage} alt="" />
      </LabelImageStyled>
      {file ? (
        <Box
          display="flex"
          alignItems="center"
          position="absolute"
          sx={{ top: 0, right: 0, zIndex: 10 }}
        >
          <IconButton
            aria-label="delete"
            color="muted"
            size="small"
            onClick={() => handlerClearFile()}
          >
            <ClearIcon fonsize="small" sx={{ width: '16px' }} />
          </IconButton>
        </Box>
      ) : null}
    </Box>
  );
};
