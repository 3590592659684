import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { BasePage } from '../../components/BasePage';
import { ShooterRegistrationForm } from '../../components/Forms';
import { fetchLocationAsync } from '../../redux/states/locationsSlice';

const ShooterRegistrationView = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLocationAsync());
  }, [dispatch]);
  return (
    <BasePage centered withBackground>
      <Box
        mx={'auto'}
        display={'flex'}
        justifyContent={'center'}
        sx={{ maxWidth: 385 }}
      >
        <ShooterRegistrationForm />
      </Box>
    </BasePage>
  );
};

export default ShooterRegistrationView;
