import styled from '@emotion/styled';

export const HistoryItem = styled.div`
  &:before {
    content: ' ';
    display: inline-flex;
    width: 18px;
    height: 12px;
    margin-right: 8px;
    background: ${(p) => {
      if (p.type === 'accepted') {
        return p.theme.palette.success.main;
      }
      if (p.type === 'vacant') {
        return p.theme.palette.info.main;
      }
    }}
`;
