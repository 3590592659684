import styled from '@emotion/styled';

export const MonthPickerWrapper = styled('div')`
  position: relative;
`;
export const MonthPickerCalendarContainer = styled('div')`
  width: 100%;
  position: absolute;
  padding: 15px 20px;
  background-color: #fff;
  border: 1px solid ${(p) => p.theme.palette.primary.main};
  font-size: 12px;
  z-index: 1;
  left: 0;
  top: calc(100% - 2px);
`;
export const MonthPickerCalendarControls = styled('div')`
  display: flex;
  align-items: center;
`;
export const MonthPickerCalendarYear = styled('div')`
  flex: 1;
  text-align: center;
`;
export const MonthPickerCalendar = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
`;

export const MonthPickerCalendarCell = styled('div')`
  padding: 10px;
  cursor: pointer;
  text-align: center;
  background: ${(p) =>
    p.isSelected ? p.theme.palette.actions.active : '#fff'};
  &:hover {
    background: ${(p) => p.theme.palette.actions.hover};
  }
`;
