import * as yup from 'yup';
import { PHONE_REG_EXP } from '../../../helpers/constants';
export const validationSchema = yup.object({
  location_id: yup.string().required('Required, choose a state'),
  event_date: yup.date().nullable().required('Required, set wedding Date'),
  getting_ready_lcation: yup.string().required('Required'),
  ceremony_location: yup
    .string()
    .typeError('you must specify a string')
    .required('Required'),
  brides_name: yup
    .string()
    .typeError('you must specify a string')
    .required('Required'),
  brides_phone: yup
    .string()
    .matches(PHONE_REG_EXP, 'Phone number is not valid')
    .required('Required'),
  grooms_name: yup
    .string()
    .typeError('you must specify a string')
    .required('Required'),
  grooms_phone: yup
    .string()
    .matches(PHONE_REG_EXP, 'Phone number is not valid')
    .required('Required'),
  coverage: yup
    .number()
    .typeError('you must specify a number')
    .required('Required'),
  start_time: yup.date().nullable().required('Required, set time'),
  photographer_videographer_start_time: yup.string().required('Required'),
  booked_package: yup.string().required('Required'),
});
