import Divider from '@mui/material/Divider';
import styled from '@emotion/styled';

export const StyledBaseDivider = styled('span')`
  display: block;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent 0%,
    ${(p) => p.theme.palette.primary.main} 50%,
    transparent 100%
  );
`;

export const StyledDivider = styled(Divider)`
  &.MuiDivider-withChildren {
    align-items: center;
    &::after,
    &::before {
      height: 1px;
      border: none;
    }
    &::after {
      background: linear-gradient(
        to right,
        transparent 0%,
        ${(p) => p.theme.palette.primary.main} 100%
      );
    }
    &::before {
      background: linear-gradient(
        to right,
        ${(p) => p.theme.palette.primary.main} 0%,
        transparent 100%
      );
    }
  }
`;
