import axios from 'axios';

export async function fetchEventsAPI(params) {
  try {
    const response = await axios.get('/events/get-events.php', {
      params,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
