import React from 'react';
import { InputStyled, LabelStyled } from './uploadFile.styled';
import Box from '@mui/material/Box';
import { ReactComponent as UploadIcon } from './images/ic-upload.svg';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

export const InputFile = ({
  disabled,
  label,
  onChange,
  file,
  value,
  handlerClearFile,
  handlerDeleteFile,
  name,
}) => {
  const isFileLink = typeof value === 'string' && value.startsWith('http');
  const isDisplayClearBtn = (!!file && typeof file === 'object') || isFileLink;

  const handlerClickClearBtn = () => {
    isFileLink
      ? handlerDeleteFile(name.replace('upload', ''))
      : handlerClearFile();
  };
  const getFileName = () => {
    if (file?.name) {
      return file.name;
    }

    if (isFileLink) {
      return (
        <a
          href={value}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <Typography variant="body1" component="span" color="primary">
            {value.substring(value.lastIndexOf('/') + 1)}
          </Typography>
        </a>
      );
    } else {
      return (
        <Typography variant="body1" component="span" color="primary">
          {value}
        </Typography>
      );
    }
  };

  return (
    <>
      <LabelStyled disabled={disabled}>
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <UploadIcon />
          </Box>
          <Typography variant="body1" color="secondary">
            {label}
          </Typography>
          <InputStyled
            disabled={disabled}
            id={label}
            name={name}
            accept=".pdf"
            type="file"
            onChange={onChange}
          />
        </Box>
      </LabelStyled>
      {file || (value && value.length) ? (
        <Box
          display="flex"
          alignItems="center"
          sx={{ opacity: disabled ? 0.5 : 1, minHeight: '34px' }}
        >
          <Typography
            component="p"
            variant="body1"
            color="muted"
            mr={1}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {getFileName()}
          </Typography>

          {isDisplayClearBtn && (
            <IconButton
              disabled={disabled}
              aria-label="delete"
              color="muted"
              size="small"
              onClick={handlerClickClearBtn}
            >
              <ClearIcon fonsize="small" sx={{ width: '16px' }} />
            </IconButton>
          )}
        </Box>
      ) : null}
    </>
  );
};
