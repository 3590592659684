import React from 'react';
import Box from '@mui/material/Box';
import { BasePage } from '../../components/BasePage';
import { LoginForm } from '../../components/Forms';

const LoginView = () => {
  return (
    <BasePage centered withBackground>
      <Box
        mx={'auto'}
        display={'flex'}
        justifyContent={'center'}
        sx={{ maxWidth: 350 }}
      >
        <LoginForm />
      </Box>
    </BasePage>
  );
};

export default LoginView;
