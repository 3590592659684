export const getAllEvents = (state) => state.events.items;
export const getEventInfo = (state) => state.events.info;
export const getEventQuery = (state) => state.events.query;
export const getEventYear = (state) => state.events.query.year;
export const getEventMonth = (state) => state.events.query.month;
export const getEventTable = (state) => state.events.query.table;
export const getEventStatus = (state) => state.events.status;
export const getEventsIdsWithoutWorkers = (state) => {
  const allEvents = getAllEvents(state);
  return allEvents
    ? allEvents
        .filter((event) => event.is_worker === '0')
        .map((event) => event.id)
    : [];
};
