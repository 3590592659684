import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';

import { KeyboardTimePickerStyled } from './TimePickerField.styled';

export const TimePickerField = ({
  onChange,
  name,
  label,
  value,
  ...restProps
}) => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardTimePickerStyled
        autoOk
        InputProps={{
          readOnly: true,
        }}
        size="small"
        variant="inline"
        color="secondary"
        format="hh:mm A"
        value={value}
        id={name}
        name={name}
        label={label}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        onChange={(data) => onChange(name, data)}
        {...restProps}
      />
    </MuiPickersUtilsProvider>
  );
};
