import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import { StyledSelectField } from './SelectField.styled';

export const SelectField = ({ value, helperText, ...props }) => {
  return (
    <StyledSelectField {...props}>
      <InputLabel id={props.id} color="secondary">
        {props.label}
      </InputLabel>
      <Select
        labelId={props.id}
        value={value ?? ''}
        variant="standard"
        {...props}
        size="medium"
        color="secondary"
      />
      {helperText && (
        <FormHelperText id="component-error-text">{helperText}</FormHelperText>
      )}
    </StyledSelectField>
  );
};
