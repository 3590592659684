import React from 'react';
import { BasePage } from '../../components/BasePage';
import { CheckEventsForm } from '../../components/Forms';
import { EventsCalendar } from '../../components/EventsCalendar';
import Box from '@mui/material/Box';

const HomeView = () => {
  return (
    <BasePage>
      <Box mb={5}>
        <CheckEventsForm />
      </Box>
      <EventsCalendar />
    </BasePage>
  );
};

export default HomeView;
