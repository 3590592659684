import React from 'react';
import axios from 'axios';
import { Switch, Route } from 'react-router-dom';
import HomeView from './views/HomeView';
import ShooterRegistrationView from './views/ShooterRegistrationView';
import WorkerEventsView from './views/WorkerEventsView';
import LoginView from './views/LoginView';
import { PrivateRoute } from './components/PrivateRoute';

axios.defaults.baseURL = `${
  process.env.NODE_ENV === 'development'
    ? 'https://vanilateam.dev:8890'
    : 'https://vanillabrides.team'
}`;

function App() {
  return (
    <Switch>
      <PrivateRoute path="/" exact>
        <HomeView />
      </PrivateRoute>
      <Route path="/shooter-registration">
        <ShooterRegistrationView />
      </Route>
      <Route path="/email-to-worker">
        <WorkerEventsView />
      </Route>
      <Route path="/login">
        <LoginView />
      </Route>
    </Switch>
  );
}

export default App;
