import TextField from '@mui/material/TextField';

import styled from '@emotion/styled';

export const StyledTextField = styled(TextField)`
  & .MuiInput-root {
    &.Mui-focused {
      background: none;
    }

    .MuiSelect-select,
    .MuiInput-input,
    .MuiInputBase-input {
      &:focus {
        background: none !important;
      }
    }

    .MuiList-root,
    .MuiMenu-list {
      padding-top: 0;
    }

    .MuiSelect-icon {
      fill: ${(props) => props.theme.palette.secondary.main};
    }
  }
`;
