import React from 'react';
import { StyledTextField } from './InputField.styled';

export const InputField = ({ value, ...props }) => {
  return (
    <StyledTextField
      value={value}
      size="medium"
      color="secondary"
      variant="standard"
      {...props}
    />
  );
};
